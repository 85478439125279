import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/signin',
    name: 'SignIn',
    component: () => import('../views/SignIn.vue')
  },
  {
    path: '/loaduserdata',
    name: 'LoadUserData',
    component: () => import('../views/LoadUserData.vue')
  },
  {
    path: '/board-members',
    name: 'BoardMembers',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ccr" */ '../views/BoardMembers.vue')
  },
  {
    path: '/board-member-list',
    name: 'BoardMemberList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ccr" */ '../views/BoardMemberList.vue')
  },
  {
    path: '/ccr',
    name: 'CCR',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ccr" */ '../views/CCR.vue')
  },
  {
    path: '/lead-results',
    name: 'LeadResults',
    component: () => import(/* webpackChunkName: "ccr" */ '../views/LeadResults.vue')
  },
  {
    path: '/lead-inventory',
    name: 'LeadInventory',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ccr" */ '../views/LeadInventory.vue')
  },
  {
    path: '/sample-results',
    name: 'SampleResults',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ccr" */ '../views/SampleResults.vue')
  },
  {
    path: '/ccr-upload',
    name: 'CCRUpload',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "ccr-upload" */ '../views/CCRUpload.vue')
  },
  {
    path: '/annual-report',
    name: 'AnnualReport',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "annual-report" */ '../views/AnnualReport.vue')
  },
  {
    path: '/annual-report-status',
    name: 'AnnualReportStatus',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "annual-report" */ '../views/AnnualReportStatus.vue')
  },
  {
    path: '/annual-report-list',
    name: 'AnnualReportList',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "annual-report-list" */ '../views/AnnualReportList.vue')
  },
  {
    path: '/rtcr',
    name: 'RTCR',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "rtcr" */ '../views/RTCR.vue')
  },
  {
    path: '/rtcr-site-plan',
    name: 'RTCRSitePlan',
    props(route) { return route.query || {} },
    component: () => import(/* webpackChunkName: "rtcr-site-plan" */ '../views/RTCRSitePlan.vue')
  },
  {
    path: '/rtcr-site-list',
    name: 'RTCRSiteList',
    component: () => import(/* webpackChunkName: "rtcr-site-plan" */ '../views/RTCRSiteList.vue')
  },
  {
    path: '/rtcr-report/:plan_id',
    name: 'RTCRReport',
    component: () => import(/* webpackChunkName: "rtcr-report" */ '../views/RTCRReport.vue')
  },
  {
    path: '/change-water-system',
    name: 'ChangeWaterSystem',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "change-water-system" */ '../views/ChangeWaterSystem.vue')
  },
  {
    path: '/sample-schedules',
    name: 'SampleSchedules',
    component: () => import(/* webpackChunkName: "rtcr-site-plan" */ '../views/SampleSchedules.vue')
  },
  {
    path: '/adduser',
    name: 'AddUser',
    component: () => import(/* webpackChunkName: "adduser" */ '../views/AddUser.vue')
  },
  {
    path: '/settings',
    name: 'Settings',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Settings.vue')
  },
  {
    path: '/email-test',
    name: 'EmailTest',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/EmailTest.vue')
  },
  {
    path: '/messages',
    name: 'Messages',
    component: () => import(/* webpackChunkName: "about" */ '../views/Messages.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/help',
    name: 'Help',
    component: () => import(/* webpackChunkName: "help" */ '../views/Help.vue')
  },
  {
    path: '/emergency',
    name: 'Emergency',
    component: () => import(/* webpackChunkName: "help" */ '../views/Emergency.vue')
  },
  {
    path: '/surveys',
    name: 'Surveys',
    component: () => import(/* webpackChunkName: "help" */ '../views/Surveys.vue')
  },
  {
    path: '/monitoring-waiver',
    name: 'MonitoringWaiver',
    component: () => import(/* webpackChunkName: "help" */ '../views/MonitoringWaiver.vue')
  },
  {
    path: '/monitoring-waiver-status',
    name: 'MonitoringWaiverStatus',
    component: () => import(/* webpackChunkName: "help" */ '../views/MonitoringWaiverStatus.vue')
  },
  {
    path: '/monitoring-waiver-receipt/:waiver_id',
    name: 'MonitoringWaiverReceipt',
    props: true,
    component: () => import(/* webpackChunkName: "help" */ '../views/MonitoringWaiverReceipt.vue')
  },
  {
    path: '/pws-files',
    name: 'PWSFiles',
    component: () => import(/* webpackChunkName: "help" */ '../views/PWSFiles.vue')
  },
  {
    path: '/signout',
    name: 'SignOut',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "signout" */ '../views/SignOut.vue')
  }

]

const router = new VueRouter({
  mode: 'hash',
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
})

export default router
